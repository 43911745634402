import React from 'react';
import { createState, useState } from '@hookstate/core';
// internal variables
const globalState = createState(
    {
        loggedIn: localStorage.getItem('jwt') ? true : false,
        profileUpdateMessage: '',
        profileUpdateVariant: null,
        profileLoaded: false,
        profileUpdate: false,
        _id: '',
        firstName: '',
        lastName: '',
        displayName: '',
        bio: '',
        email: '',
        password: '',
        themePreference: '',
        notificationsLastDate: null,
        updateTime: Date.now(),
        stripe: {},
    }
);
const wrapState = (s) => ({
    get: () => s.value,
    set: (t) => s.set(t)
})
// The following 2 functions can be exported now:
export const accessGlobalStateProfile = () => wrapState(globalState)
export const useGlobalStateProfile = () => wrapState(useState(globalState))
