import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Function to check domain and redirect if necessary
function checkAndRedirect() {
  const hostname = window.location.hostname;
  if (hostname.endsWith(".xyz")) {
    const newHostname = hostname.slice(0, -3) + "courses";
    window.location.href = `${window.location.protocol}//${newHostname}${window.location.pathname}${window.location.search}`;
    return true; // Indicates that a redirect occurred
  }
  return false; // No redirect needed
}

// Perform the check before rendering the app
if (!checkAndRedirect()) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
