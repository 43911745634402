import React from 'react';
import { createState, useState, State } from '@hookstate/core';
// internal variables
const globalState = createState(
    {
        theme: 'dark' //localStorage.getItem('themePreference') ? localStorage.getItem('themePreference') : 'light',
    }
);
const wrapState = (s) => ({
    get: () => s.value,
    set: (t) => s.set(t)
})
// The following 2 functions can be exported now:
export const accessGlobalStateTheme = () => wrapState(globalState)
export const useGlobalStateTheme = () => wrapState(useState(globalState))
