import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import seo from './seo.js';

const PageTitleUpdater = () => {
    const location = useLocation();
    const stopwords = ['and','for','with'];
  
    useEffect(() => {
        const paths = location.pathname.split('/');
        const path  = paths.slice(1,)//[paths.length - 1];

        let pageTitle = path.map(
            (p)=> p
                .split('-')
                .map((word)=>{
                    if(stopwords.includes(word)) {
                        return word;
                    } else {
                        return word.length > 1 ? word[0].toUpperCase() + word.slice(1,) : word;
                    }
                }).join(" ")
        ).join(" > ");

      seo({
        title: pageTitle,
        //metaDescription: 'With some meta description'
      });
    }, [location]);
  
    return null; // This component doesn't render anything
}

export default PageTitleUpdater;